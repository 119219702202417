<template>
	<div id="app">
		<component v-if="isLogin" :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
import { ref } from 'vue'
import { loginByFeishu } from './http/api'
const getQueryString = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var u = decodeURIComponent(window.location.search).substr(1).match(reg)
  if (u != null) {
    return unescape(u[2])
  }
  return null
}
export default ({
  computed: {
    layout() {
      return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
    }
  },
  data() {
    return {
      isLogin: false
    }
  },
  beforeMount() {
    const code = getQueryString('code') || ''
    let isLogin = sessionStorage.getItem('isLogin') || ''
    if (isLogin != 'true') {
      if (!code) {
        let url = window.location.href
        url = encodeURIComponent(url)
        window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a4d279fb8bf91013&redirect_uri=${url}`
      } else {
        loginByFeishu({variables: {code}}).then(res => {
          let {sessionToken, user} = res.data.loginByFeishu
          localStorage.setItem('sessionToken', sessionToken)
          localStorage.setItem('userInfo', JSON.stringify(user))
          sessionStorage.setItem('isLogin', 'true')
          this.isLogin = true
        })
      }
    } else {
      this.isLogin = true
    }
  },
})
</script>

<style lang="scss">
</style>