export const wxworkGroupNamesQuery = () => {
  return {
    query: `query {
      wxworkChat {
        groupsStat
      }
    }`
  }
}


export const loginByFeishuQuery = () => {
  return {
    query: `mutation(
      $code: String!
    ) {
      loginByFeishu(
        code: $code
      ) {
        user {
          id
          nickname
          avatarUrl
          feishuUnionId
          permissions
        }
        sessionToken
        isNewbie
      }
    }`
  }
}

export const setWxWorkGroupLeaderQuery = () => {
  return {
    query: `mutation(
      $groupName: String!,
      $leader: GroupLeaderInput
    ) {
      setWxWorkGroupLeader(
        groupName: $groupName
        leader: $leader
      ) {
        isOk
      }
    }`
  }
}

export const wxworkGroupDetailQuery = () => {
  return {
    query: `query(
      $groupName: String!
      $aStart: Timestamp!
      $bStart: Timestamp!
      $sender: String
    ) {
      a: wxworkChat {
        group(groupName: $groupName) {
          leader {
            name
            nickname
            phone
          }
          userCount
          createdAt
          lastMsgAt
        }
      }
      b: wxworkChat {
        group(groupName: $groupName) {
          msgCount(start: $aStart)
        }
      }
      c: wxworkChat {
        group(groupName: $groupName) {
          msgCount(start: $bStart)
        }
      }
      d: wxworkChat {
        group(groupName: $groupName) {
          msgCount(start: $bStart, sender: $sender)
        }
      }
      e: wxworkChat {
        group(groupName: $groupName) {
          msgCount(start: $bStart, sender: $sender)
        }
      }
    }`
  }
}

export const autoReplyQuery = () => {
  return {
    query: `query {
      gigaBot {
        autoReply
      }
    }`
  }
}

export const managersQuery = () => {
  return {
    query: `query {
      wxworkChat {
        managers
      }
    }`
  }
}

export const operationManagersQuery = () => {
  return {
    query: `query {
      wxworkChat {
        operationManagers {
          id
          userid
          name
          alias
          avatar
          mobile
          customerCount
          realName
          phoneName
          tagsStatistics
        }
      }
    }`
  }
}

export const createGigaBotAutoReplyQuery = () => {
  return {
    query: `mutation(
      $images: [Image]
      $isPrivateChat: Boolean
      $keyword: String!
      $links: [Link]
      $minPrograms: [MinProgram]
      $texts: [Text]
      $videos: [Video]
    ) {
      createGigaBotAutoReply(
        images: $images
        isPrivateChat: $isPrivateChat
        keyword: $keyword
        links: $links
        minPrograms: $minPrograms
        texts: $texts
        videos: $videos
      ) {
        configs
      }
    }`
  }
}

export const updateWechatManagerQuery = () => {
  return {
    query: `mutation(
      $phoneName: String
      $realName: String
      $userid: String!
    ) {
      updateWechatManager(
        phoneName: $phoneName
        realName: $realName
        userid: $userid
      ) {
        isOk
      }
    }`
  }
}