<template>
	<component :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top">
		<a-layout-header>
			<a-row type="flex">
				<a-col :span="24" :md="6">
					<a-breadcrumb>
						<a-breadcrumb-item><router-link to="/"> Pages</router-link></a-breadcrumb-item>
						<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
					</a-breadcrumb>
					<!-- <div class="ant-page-header-heading">
						<span class="ant-page-header-heading-title">{{ this.$route.name }}</span>
					</div> -->
				</a-col>
				<a-col :span="24" :md="18" class="header-control">
          <div class="user">
            <img :src="userInfo.avatarUrl" alt="">
            <span>{{ userInfo.nickname }}</span>
          </div>
				</a-col>
			</a-row>
		</a-layout-header>
	</component>

</template>

<script>

	export default ({
		props: {
			// Header fixed status.
			navbarFixed: {
				type: Boolean,
				default: false,
			},

			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				top: 0,
				searchLoading: false,
				wrapper: document.body,
        userInfo: {}
			}
		},
		methods: {
			resizeEventHandler(){
				this.top = this.top ? 0 : -0.01 ;
			},
			onSearch(value){
			}
		},
		mounted() {
			this.wrapper = document.getElementById('layout-dashboard');
      let userInfo = localStorage.getItem('userInfo')
      if (userInfo) userInfo = JSON.parse(userInfo)
      this.userInfo = userInfo
		},
		created() {
			window.addEventListener("resize", this.resizeEventHandler);
		},
		destroyed() {
			window.removeEventListener("resize", this.resizeEventHandler);
		},
	})

</script>
<style lang="scss" scoped>
  .user {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
</style>
