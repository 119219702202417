import { post } from './axios'
const { wxworkGroupNamesQuery, loginByFeishuQuery, setWxWorkGroupLeaderQuery, wxworkGroupDetailQuery, autoReplyQuery, createGigaBotAutoReplyQuery,
  managersQuery, operationManagersQuery, updateWechatManagerQuery } = require('./query')

export const getManagers = (data = {}) => post('/api/graphql/', Object.assign(data, managersQuery()))
export const getWxworkGroupNames = (data = {}) => post('/api/graphql/', Object.assign(data, wxworkGroupNamesQuery()))
export const loginByFeishu = (data = {}) => post('/api/graphql/', Object.assign(data, loginByFeishuQuery()))
export const setWxWorkGroupLeader = (data = {}) => post('/api/graphql/', Object.assign(data, setWxWorkGroupLeaderQuery()))
export const getWxworkGroupDetail = (data = {}) => post('/api/graphql/', Object.assign(data, wxworkGroupDetailQuery()))
export const getAutoReply = (data = {}) => post('/api/graphql/', Object.assign(data, autoReplyQuery()))
export const createGigaBotAutoReply = (data = {}) => post('/api/graphql/', Object.assign(data, createGigaBotAutoReplyQuery()))
export const getOperationManagers = (data = {}) => post('/api/graphql/', Object.assign(data, operationManagersQuery()))
export const updateWechatManager = (data = {}) => post('/api/graphql/', Object.assign(data, updateWechatManagerQuery()))